import { setSkin } from "@/utils/skin";
import home from "@/view/home/home";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const routes = [
  //   {
  //     path: "/",
  //     component: htmlo,
  //     meta: {
  //
  //     },
  //     children: [
  //       {
  //         path: "/",
  //         component: (resolve) => require(["@/view/htmlo/index.vue"], resolve),
  //         meta: {
  //
  //         },
  //       },
  //     ],
  //   },
  {
    path: "/",
    component: home,
    meta: {
      title: "Paleogeography",
    },
    children: [
      {
        path: "/",
        component: (resolve) => require(["@/view/home/index.vue"], resolve),
        meta: {},
      },
      {
        path: "/oauth",
        component: (resolve) => require(["@/view/home/oauth.vue"], resolve),
        meta: {},
      },
      {
        path: "/oauth/:source",
        component: (resolve) => require(["@/view/home/oauth.vue"], resolve),
        meta: {},
      },
      {
        path: "/html0",
        component: (resolve) => require(["@/view/htmlo/index.vue"], resolve),
        meta: {},
      },
      {
        path: "/links",
        component: (resolve) => require(["@/view/link/index.vue"], resolve),
        meta: {},
      },
      {
        path: "/articleInfo",
        component: (resolve) => require(["@/view/article/index.vue"], resolve),
        meta: {},
      },
      {
        path: "/message",
        component: (resolve) => require(["@/view/message/index.vue"], resolve),
        meta: {},
      },
      {
        path: "/about",
        component: (resolve) => require(["@/view/about/index.vue"], resolve),
        meta: {},
      },
      {
        path: "/search",
        component: (resolve) => require(["@/view/search/Search"], resolve),
        meta: {},
      },
      {
        path: "/archive",
        component: (resolve) => require(["@/view/archive/index"], resolve),
        meta: {},
      },
      {
        path: "/categorys",
        component: (resolve) =>
          require(["@/view/category/Category.vue"], resolve),
        meta: {},
      },
      {
        path: "/tag",
        component: (resolve) => require(["@/view/tag/Tag"], resolve),
        meta: {},
      },
      {
        path: "/photo",
        component: (resolve) => require(["@/view/photo/index"], resolve),
        meta: {},
      },
      {
        path: "/sponsor",
        component: (resolve) => require(["@/view/sponsor/index"], resolve),
        meta: {},
      },
      {
        path: "/im",
        component: (resolve) => require(["@/view/im/index"], resolve),
      },
      {
        path: "/hot",
        component: (resolve) => require(["@/view/search/Hot.vue"], resolve),
        meta: {},
      },
      {
        path: "/note",
        component: (resolve) => require(["@/view/note/index.vue"], resolve),
        meta: {},
      },
      {
        path: "/notice",
        component: (resolve) => require(["@/view/notice/index.vue"], resolve),
        meta: {},
      },
      {
        path: "/blog1",
        component: (resolve) => require(["@/view/blogs/blog1.vue"], resolve),
        meta: {},
      },
      {
        path: "/blog-user",
        component: (resolve) =>
          require(["@/view/blogs/blog-user.vue"], resolve),
        meta: {},
      },
      {
        path: "/blog-home",
        component: (resolve) =>
          require(["@/view/blogs/blog-home.vue"], resolve),
        meta: {},
      },
      {
        path: "/blog-detail",
        component: (resolve) =>
          require(["@/view/blogs/blog-detail.vue"], resolve),
        meta: {},
      },
      {
        path: "/news",
        component: (resolve) => require(["@/view/news/index.vue"], resolve),
        meta: {},
      },
      {
        path: "/news-detail",
        component: (resolve) => require(["@/view/news/detail.vue"], resolve),
        meta: {},
      },
      {
        path: "/event",
        component: (resolve) => require(["@/view/event/index.vue"], resolve),
        meta: {},
      },
      {
        path: "/event-detail",
        component: (resolve) => require(["@/view/event/detail.vue"], resolve),
        meta: {},
      },
      {
        path: "/partners",
        component: (resolve) => require(["@/view/partners/index.vue"], resolve),
        meta: {},
      },
      {
        path: "/history1",
        component: (resolve) =>
          require(["@/view/history/history1.vue"], resolve),
        meta: {},
      },
      {
        path: "/history1-tran",
        component: (resolve) =>
          require(["@/view/history/history1-tran.vue"], resolve),
        meta: {},
      },
      {
        path: "/history1-sub",
        component: (resolve) =>
          require(["@/view/history/history1-sub.vue"], resolve),
        meta: {},
      },
      {
        path: "/history1-zhu",
        component: (resolve) =>
          require(["@/view/history/history1-zhu.vue"], resolve),
        meta: {},
      },
      {
        path: "/history1-detail",
        component: (resolve) =>
          require(["@/view/history/history1-detail.vue"], resolve),
        meta: {},
      },
      {
        path: "/history2",
        component: (resolve) =>
          require(["@/view/history/history2.vue"], resolve),
        meta: {},
      },
      {
        path: "/history3",
        component: (resolve) =>
          require(["@/view/history/history3.vue"], resolve),
        meta: {},
      },
      {
        path: "/history4",
        component: (resolve) =>
          require(["@/view/history/history4.vue"], resolve),
        meta: {},
      },
      {
        path: "/history4-detail",
        component: (resolve) =>
          require(["@/view/history/history4-detail.vue"], resolve),
        meta: {},
      },
      {
        path: "/resources",
        component: (resolve) =>
          require(["@/view/resources/index.vue"], resolve),
        meta: {},
      },
      {
        path: "/about1",
        component: (resolve) => require(["@/view/about/about1.vue"], resolve),
        meta: {},
      },
      {
        path: "/about2",
        component: (resolve) => require(["@/view/about/about2.vue"], resolve),
        meta: {},
      },
      {
        path: "/about3",
        component: (resolve) => require(["@/view/about/about3.vue"], resolve),
        meta: {},
      },
      {
        path: "/user1",
        component: (resolve) => require(["@/view/user/user1.vue"], resolve),
        meta: {},
      },
      {
        path: "/user2",
        component: (resolve) => require(["@/view/user/user2.vue"], resolve),
        meta: {},
      },
      {
        path: "/user3",
        component: (resolve) => require(["@/view/user/user3.vue"], resolve),
        meta: {},
      },
      {
        path: "/user4",
        component: (resolve) => require(["@/view/user/user4.vue"], resolve),
        meta: {},
      },
      {
        path: "/user5",
        component: (resolve) => require(["@/view/user/user5.vue"], resolve),
        meta: {},
      },
      {
        path: "/use",
        component: (resolve) => require(["@/view/user/user-use.vue"], resolve),
        meta: {},
      },
      {
        path: "/policy",
        component: (resolve) =>
          require(["@/view/user/user-policy.vue"], resolve),
        meta: {},
      },
      {
        path: "/settings",
        component: (resolve) => require(["@/view/user/settings.vue"], resolve),
        meta: {},
      },
    ],
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("@/view/404/404.vue"),
  },
];

const scrollBehavior = function (to, from, savedPosition) {
  if (to.hash) {
    console.log("==scrollBehavior", to.hash);
    return {
      // 通过 to.hash 的值來找到对应的元素
      selector: to.hash,
      top: -200,
    };
  }
  return { y: 0 };
};

const router = new VueRouter({
  mode: "history",
  // scrollBehavior: () => ({ y: 0 }),
  scrollBehavior,
  routes,
});
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

router.beforeEach((to, from, next) => {
  setSkin();
  next();
});
export default router;
