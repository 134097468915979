import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    skin: "shallow", //shallow浅色模式  //deep深色模式
    loginFlag: false,
    showSign: true,
    drawer: false,
    searchDrawer: false,
    siteAccess: 0,
    visitorAccess: 0,
    systemNotcie: {},
    userInfoDrawer: { flag: false, name: null, edit: false },
    userInfo: sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : null,
    isCommentFlag: false,
    articleDrawer: {
      flag: false,
      id: null,
    },
    webSiteInfo: {
      loginTypeList: "",
      showList: "",
    },
    hotArticles: {},
    isSetting: false,
    msgIngroUids: localStorage.getItem("msgIngroUids") ? JSON.parse(localStorage.getItem("msgIngroUids")) : [],
    joinMailFlag: false,
  },
  mutations: {
    setJoinMailFlag(state, newValue) {
      state.joinMailFlag = newValue;
    },
    closeModel(state) {
      state.loginFlag = false;
    },

    isCommentFlag(state, newValue) {
      state.isCommentFlag = newValue;
    },
    setWebSiteInfo(state, newValue) {
      state.webSiteInfo = newValue;
    },
    setHotArticles(state, newValue) {
      state.hotArticles = newValue;
    },
    setSkin(state, newValue) {
      state.skin = newValue;
    },
    setDrawer(state, newValue) {
      state.drawer = newValue;
    },
    setUserInfoDrawer(state, newValue) {
      state.userInfoDrawer = newValue;
    },
    setSearchDrawer(state, newValue) {
      state.searchDrawer = newValue;
    },
    setLoginFlag(state, newValue) {
      state.showSign = true;
      state.loginFlag = newValue;
    },
    setShowSign(state, newValue) {
      state.showSign = newValue;
    },

    setUserInfo(state, newValue) {
      state.userInfo = newValue;
      sessionStorage.removeItem("user");
      sessionStorage.setItem("user", JSON.stringify(newValue));
      state.isSetting = (newValue || {}).logged > 0 || (newValue || {}).isSetting;
    },
    setSystemNotice(state, newValue) {
      state.systemNotcie = newValue;
    },
    setMsgIngroUids(state, newValue) {
      localStorage.setItem("msgIngroUids", JSON.stringify(newValue));
      state.msgIngroUids = newValue;
    },
  },

  // actions: {
  //   setSkin(context, value) {
  //     // 修改getskin的值
  //     context.commit('getskin', value)
  //   }
  // },
});
