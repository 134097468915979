<template>
  <div>
    <el-dialog :lock-scroll="false" :fullscreen="true" title="Post Article" :visible.sync="drawer" :before-close="handleClose" z-index="20001">
      <el-form :model="article" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Article title" prop="title">
              <el-input v-model="article.title"></el-input>
            </el-form-item>
            <el-form-item label="Overview" prop="summary">
              <el-input v-model="article.summary"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Cover" prop="">
              <cropper-img v-model="article.avatar" :height="142" :width="230" style="height: 142px;width: 230px;"></cropper-img>

              <!-- <el-upload class="avatar-uploader" :show-file-list="false" ref="upload" name="filedatas" :action="uploadPictureHost" :before-upload="uploadBefore" :http-request="uploadSectionFile" multiple>
                <img v-if="article.avatar" style="width: 100%; height: 100%" :src="article.avatar" class="imgAvatar" />
                <i v-else class="el-icon-plus avatar-img-icon"></i>
              </el-upload> -->
              <div class="tips">
                Format：jpg，png <br />
                Size：Within 3M <br />
                Dimensions：230*142
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="Tags" prop="tagList">
              <el-tag v-for="(item, index) of article.tagList" :key="index" style="margin: 0 1rem 0 0" :closable="true" @close="removeTag(item)">
                {{ item }}
              </el-tag>
              <!-- 标签选项 -->
              <el-popover placement="bottom-start" width="460" trigger="click" v-if="article.tagList && article.tagList.length < 3">
                <div class="popover-title">Tags</div>
                <!-- 搜索框 -->
                <el-input style="width: 100%" v-model="tagName" placeholder="Please enter a tag name, enter to add a customized tag." @keyup.enter.native="saveTag" />
                <!-- 标签 -->
                <div class="popover-container">
                  <div>Add Tags</div>
                  <el-tag v-for="(item, index) of tagList" :key="index" style="margin-left: 3px; margin-top: 2px" :class="tagClass(item)" @click="addTag(item)">
                    {{ item.name }}
                  </el-tag>
                </div>
                <el-button type="primary" plain slot="reference" size="small"> Add Tags </el-button>
              </el-popover>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item v-if="article.isPublish == 0" label="Reason for delisting" label-width="160px">
              <el-input v-model="article.remark" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="Content" prop="contentMd">
          <el-row style="margin-bottom: 20px">
            <el-col align="right">
              <span v-show="article.contentType === '1'" style="color: #e6a23c; margin-right: 20px">Markdown editor will overwrite other rich text editor content after saving</span>
              Editor：
              <el-select v-model="article.contentType" placeholder="请选择">
                <el-option key="1" label="Markdown (recommend)" value="1" />
                <el-option key="2" label="Tinymce rich text editor" value="2" />
              </el-select>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <mavon-editor v-show="article.contentType === '1'" placeholder="Please enter..." style="height: 500px" ref="md" v-model="article.contentMd" @imgDel="imgDel" @imgAdd="imgAdd" :language="'en'" />
              <Tinymce v-show="article.contentType === '2'" :height="500" v-model="article.content"></Tinymce>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button @click="savedarft(false)">Save to Draft</el-button>
        <el-button type="primary" @click="submit">Submit</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible" width="30%" :before-close="handleCloseDialog">
      <span>Leaving the editor, do you need to save the articles you write？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogSubmit(false)">Don't Save</el-button>
        <el-button type="primary" @click="dialogSubmit(true)">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { featchCategory, fetchTagList, getMyArticleInfo, insertArticle, upload } from "@/api";
export default {
  data() {
    return {
      uploadPictureHost: process.env.VUE_APP_BASE_API + "/file/upload",
      article: {},
      categoryList: [],
      // 加载层信息
      loading: [],
      img: process.env.VUE_APP_IMG_API,
      tagList: [],
      rules: {
        title: [
          {
            required: true,
            message: "Please enter",
            trigger: "blur",
          },
        ],
        avatar: [{ required: true, message: "Please enter", trigger: "change" }],
        summary: [{ required: true, message: "Please enter", trigger: "blur" }],
        tagList: [{ required: true, message: "Please enter", trigger: "blur" }],
        categoryId: [{ required: true, message: "Please enter", trigger: "blur" }],
        isOriginal: [{ required: true, message: "Please enter", trigger: "blur" }],
        originalUrl: [{ required: true, message: "Please enter", trigger: "blur" }],
        // contentMd: [{ required: true, message: "Please enter", trigger: "blur" }],
      },
      confirmButtonClass: {
        "border-color": "#bb490a !important",
        background: "#bb490a !important",
      },
      dialogVisible: false,
      isSavedarft: false,
      tagName: "",
    };
  },
  computed: {
    drawer: {
      set(value) {
        this.$store.state.articleDrawer.flag = value;
      },
      get() {
        if (this.$store.state.articleDrawer.flag) {
          this.article = {
            isOriginal: 1,
            avatar: null,
            contentType: "1",
            tagList: [],
          };
          featchCategory().then((res) => {
            this.categoryList = res.data;
          });
          fetchTagList({ typec: "blog" }).then((res) => {
            this.tagList = res.data;
          });
          if (this.$store.state.articleDrawer.id) {
            this.openLoading();
            getMyArticleInfo(this.$store.state.articleDrawer.id).then((res) => {
              this.article = res.data;
            });
            this.loading.close(0);
          }
        }
        return this.$store.state.articleDrawer.flag;
      },
    },
    tagClass() {
      return function (item) {
        const index = this.article.tagList.indexOf(item.name);
        return index !== -1 ? "tag-item-select" : "tag-item";
      };
    },
  },
  watch: {
    article: {
      handler(new_value, old_value) {
        this.isSavedarft = false;
      },
      deep: true,
    },
  },
  methods: {
    addTag: function (item) {
      if (this.article.tagList.length > 2) {
        this.$message.error("Add up to three tags, if you want to add more, please delete one first!");
        return false;
      }
      if (this.article.tagList.indexOf(item.name) === -1) {
        this.article.tagList.push(item.name);
      }
    },
    saveTag: function () {
      if (this.tagName.trim() !== "") {
        this.addTag({
          name: this.tagName,
        });
        this.tagName = "";
      }
    },
    removeTag: function (item) {
      const index = this.article.tagList.indexOf(item);
      this.article.tagList.splice(index, 1);
    },
    dialogSubmit(action) {
      if (action) {
        this.savedarft(true);
      } else {
        this.$store.state.articleDrawer.flag = false;
      }
      this.dialogVisible = false;
    },
    handleCloseDialog() {
      this.dialogVisible = false;
    },
    savedarft(isclose) {
      this.article.isPublish = 3;
      insertArticle(this.article)
        .then((res) => {
          this.$message.success("success");
          if (isclose) {
            this.$store.state.articleDrawer.flag = false;
            location.reload();
          }
          this.isSavedarft = true;
          if (res.data) this.article = res.data;
        })
        .catch((err) => {});
    },
    imgAdd: function (pos, $file) {
      var formdata = new FormData();
      formdata.append("multipartFile", $file);
      upload(formdata)
        .then((res) => {
          this.$refs.md.$img2Url(pos, res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    imgDel: function (filename) {
      delBatchFile(filename[0].split(this.img)[1]);
    },
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.article.contentType == "1") {
            this.article.content = this.$refs.md.d_render;
          }
          if (!this.article.content) {
            this.$message.error("Please prepare the content of the article！");
            return;
          }
          this.article.isPublish = 1;
          insertArticle(this.article)
            .then((res) => {
              this.$store.state.articleDrawer.flag = false;
              this.$message.success("Published Successfully");
              location.reload();
            })
            .catch((err) => {});
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleClose() {
      if (this.isSavedarft) {
        this.$store.state.articleDrawer.flag = false;
        location.reload();
        return;
      }

      // this.dialogVisible=true
      this.$confirm("Leaving the editor, do you need to save the articles you write？", {
        confirmButtonText: "Save",
        confirmButtonClass: "confirmButtonClass",
        cancelButtonText: "Don't Save",
        cancelButtonClass: "cancelButtonClass",
        distinguishCancelAndClose: true,
      })
        .then((_) => {
          this.savedarft(true);
        })
        .catch((action) => {
          if (action === "cancel") {
            this.$message.info("cancel");
            this.$store.state.articleDrawer.flag = false;
            location.reload();
          }
        });
    },
    handleUploadImage(event, insertImage, files) {
      // 拿到 files 之后上传到文件服务器，然后向编辑框中插入对应的内容
      this.openLoading();
      const file = files[0];
      // FormData 对象
      var formData = new FormData();
      // 文件对象
      formData.append("multipartFile", file);
      upload(formData).then((res) => {
        insertImage({
          url: res.data,
          desc: file.name,
          width: "100%",
          height: "300px",
        });
      });
      this.loading.close();
    },
    uploadBefore: function () {
      this.openLoading();
    },
    uploadSectionFile: function (param) {
      this.files = param.file;
      // FormData 对象
      var formData = new FormData();
      // 文件对象
      formData.append("multipartFile", this.files);
      upload(formData).then((res) => {
        this.article.avatar = res.data;
      });
      this.loading.close();
    },
    // 打开加载层
    openLoading: function () {
      this.loading = this.$loading({
        lock: true,
        text: "loading~",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
        fullscreen: false,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tips {
  margin-top: 6px;
  font-size: 12px;
  color: #aa4415;
  line-height: 20px;
}
/deep/ .avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 100px;
  text-align: center;
}

/deep/ .el-upload {
  width: 100%;
  height: 100%;
}

/deep/ .avatar-uploader:hover {
  border-color: #bb490a;
}

/deep/ .el-icon-plus {
  font-size: 28px;
  color: #8c939d;
  line-height: 100px;
}

/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

<style lang="scss" scoped>
/deep/.el-radio.is-bordered.is-checked {
  border-color: #bb490a;
}

/deep/.el-radio__input.is-checked + .el-radio__label {
  color: #bb490a;
}

/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: #bb490a;
  background: #bb490a;
}
/deep/.el-button:hover,
/deep/.el-button:focus,
/deep/.el-button:active {
  border-color: #dcdfe6;
  background: none;
  color: #bb490a;
}
</style>
<style lang="scss" scoped>
button {
  ::v-deep.el-button--primary {
    border-color: #bb490a;
    background: #bb490a;
  }
  ::v-deep.el-button--primary.is-plain {
    color: #fff;
  }
  ::v-deep.el-button--primary.is-plain:hover {
    color: #bb490a;
  }
}
.el-tag {
  color: #bb490a;
  background-color: #faebe3;
  border-color: #faebe3;
}
/deep/.el-tag .el-tag__close {
  color: #bb490a;
}
/deep/.el-tag .el-tag__close:hover {
  border-color: #bb490a;
  background: #bb490a;
  color: #fff;
}
</style>
<style>
.confirmButtonClass {
  border-color: #bb490a !important;
  background: #bb490a !important;
}
.cancelButtonClass:hover {
  border-color: #dcdfe6 !important;
  color: #bb490a !important;
  background: none !important;
}

.v-note-help-wrapper {
  z-index: 999999 !important;
}
</style>
