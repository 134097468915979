<template>
  <div>
    <!-- 头部 -->
    <Header :userInfo="userInfo" @childEvent="handleChildEvent"></Header>
    <!-- 侧边导航栏 -->
    <SideNavBar></SideNavBar>
    <Notice></Notice>
    <!-- 内容 -->
    <transition name="moveCartoon" appear>
      <div v-if="validate" style="min-height: calc(100vh - 167px)">
        <router-view :key="$route.fullPath" />
      </div>
      <div v-else style="min-height: 100vh">
        <router-view :key="$route.fullPath" />
      </div>
    </transition>

    <!-- 底部 -->
    <Footer v-show="$route.path != '/message'"></Footer>

    <!-- 登录模态框 -->
    <Login></Login>
    <SearchModle></SearchModle>
    <!-- 个人中心 -->
    <User></User>
    <!-- 发表文章 -->
    <ArticleModel></ArticleModel>
    <!-- 侧边栏 -->
    <Sidebar></Sidebar>

    <el-dialog :visible.sync="dialogVisiable" class="registerDialog" :show-close="false">
      <div class="changePass-box">
        <p class="btitle">
          Download brand assets
        </p>
        <div class="imgBox">
          <img src="../../imgs/downLoad/downLoad.png">
        </div>
        <div class="btn-box">
          <div class="btn2" @click="handleAddCalendar">
            Download zip file
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getWebSiteInfo, selectUserInfoByToken } from "@/api";
import { getNewSystemNotice } from "@/api/im";
import Footer from "@/components/layout/Footer.vue";
import Header from "@/components/layout/Header.vue";
import SearchModle from "@/components/layout/Search.vue";
import SideNavBar from "@/components/layout/SideNavBar.vue";
import Sidebar from "@/components/layout/Sidebar.vue";
import Login from "@/components/model/Login.vue";
import Notice from "@/components/notice/Notice.vue";
import { getToken, setToken, removeToken } from "@/utils/cookieUtil";
import ArticleModel from "@/view/article/Article.vue";
import User from "@/view/user/User.vue";
export default {
  components: {
    Header,
    Footer,
    Sidebar,
    Login,
    SideNavBar,
    SearchModle,
    User,
    ArticleModel,
    Notice,
  },
  data() {
    return {
      userInfo: null,
      dialogVisiable: false
    };
  },
  methods: {
    handleAddCalendar() {
      this.dialogVisiable = false
      // const bloba = "/public/paleogeography.zip"
      // const blob = new Blob([bloba], { type: "text/zip" });
      const link = document.createElement("a");
      // link.href = window.URL.createObjectURL(blob);
      link.href = "https://static.paleogeography.com/paleogeography.zip";
      link.download = "paleogeography.zip";
      link.click();
    },
    handleChildEvent(message) {
      this.dialogVisiable = message;
    },
    validate() {
      return this.$route.path == "/search" || this.$route.path == "/category";
    },
  },
  created() {
    let flag = window.location.href.indexOf("token") != -1;
    if (flag) {
      let token = this.$route.query.token;
      setToken(token);
    }

    // 从cookie中获取token
    let token = getToken();
    if (token != null) {
      selectUserInfoByToken(token)
        .then((res) => {
          this.userInfo = res.data;
          this.$store.commit("setUserInfo", res.data);
        })
        .catch((err) => {
          removeToken();
          this.$store.commit("setUserInfo", null);
        });
    }
    getWebSiteInfo().then((res) => {
      this.$store.commit("setWebSiteInfo", res.data);
      this.$store.commit("setHotArticles", res.extra.hotArticles);

      this.$store.state.siteAccess = res.extra.siteAccess;
      this.$store.state.visitorAccess = res.extra.visitorAccess;
    });
    if (this.$store.state.userInfo) {
      if (getToken()) {
        getNewSystemNotice().then((res) => {
          this.$store.commit("setSystemNotice", res.data);
        });
      }
    }

    // //跳回到原地址
    // if (flag) {
    //     // 跳转回原页面
    //     const loginUrl = getUrl();
    //     if (loginUrl != null && loginUrl != "") {
    //         if (loginUrl.indexOf("articleId") != -1) {
    //             let arr = loginUrl.split("=")
    //             this.$router.push({ path: '/articleInfo', query: { articleId: arr[1] } })
    //         } else {
    //             this.$router.push({ path: loginUrl });
    //         }
    //     } else {
    //         this.$router.push({ path: "/" });
    //     }
    // }
  },
};
</script>
<style scoped>
/* 类名要对应回 name 的属性值 */
.moveCartoon-enter-active {
  animation: move 0.5s;
}

.moveCartoon-leave-active {
  animation: move 0.5s reverse;
}

@keyframes move {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translate(0);
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 600px) {
  .registerDialog {
    /deep/.el-dialog {
      width: 95%;
    }
  }
}

.changePass-box {
  width: 100%;

  .btitle {
    font-size: 20px;
    font-weight: bold;
    color: #121212;
    line-height: 30px;
    margin-bottom: 25px;
  }

  .imgBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }

  .btn-box {
    // border: 1px solid red;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: 184px;
      height: 46px;

      font-size: 16px;
      text-align: center;
      font-weight: bold;
      color: #ffffff;
      line-height: 46px;
      cursor: pointer;
    }

    .btn2 {
      background: #bb490a;
    }
  }
}
</style>
