import Vue from "vue";
import App from "./App.vue";
import "./assets/font/iconfont.css";
import "./assets/font/iconfont.js";
import config from "./assets/js/config";
import store from "./store";

import "@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css";
import createCopyCodePlugin from "@kangc/v-md-editor/lib/plugins/copy-code/index";
import VMdPreview from "@kangc/v-md-editor/lib/preview";

import { setSkin } from "@/utils/skin";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import jsCookie from "js-cookie";
import { vueBaberrage } from "vue-baberrage";
import VueImageSwipe from "vue-image-swipe";
import "vue-image-swipe/dist/vue-image-swipe.css";
import "../src/icons";

import VueMarkdownEditor from "@kangc/v-md-editor";
import "@kangc/v-md-editor/lib/style/base-editor.css";
import "@kangc/v-md-editor/lib/theme/style/vuepress.css";
import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js";
import Clipboard from "clipboard";
import mavonEditor from "mavon-editor";
import "mavon-editor/dist/css/index.css";
import Prism from "prismjs";

Vue.prototype.Clipboard = Clipboard;

import hljs from "highlight.js";

import { clickShare, datefomat, datefomat1, datefomat2, datefomat3, datefomat4, windowOpen, _isMobile } from "@/utils";
Vue.filter("datefomat", datefomat);
Vue.filter("datefomat1", datefomat1);
Vue.filter("datefomat2", datefomat2);
Vue.filter("datefomat3", datefomat3);
Vue.filter("datefomat4", datefomat4);
Vue.prototype.clickShare = clickShare;
Vue.prototype.$windowOpen = windowOpen;
Vue.prototype._isMobile = _isMobile;

import "highlight.js/styles/atom-one-dark-reasonable.css"; //样式
import MetaInfo from "vue-meta-info";
import router from "./router";
//创建v-highlight全局指令
Vue.directive("highlight", function (el) {
  let blocks = el.querySelectorAll("pre code");
  blocks.forEach((block) => {
    hljs.highlightBlock(block);
  });
});

Vue.use(MetaInfo);
Vue.use(mavonEditor);

VueMarkdownEditor.use(vuepressTheme, {
  Prism,
});
Vue.use(VueMarkdownEditor);
VMdPreview.use(vuepressTheme, {
  Prism,
});
VMdPreview.use(createCopyCodePlugin());
Vue.use(VMdPreview);

Vue.use(VueImageSwipe);
import locale from 'element-ui/lib/locale/lang/en'
Vue.use(ElementUI, { locale })
// Vue.use(ElementUI);
Vue.prototype.$cookie = jsCookie; // 在页面里可直接用 this.$cookie 调用
Vue.prototype.$setSkin = setSkin;
Vue.use(vueBaberrage);
Vue.config.productionTip = false;
Vue.prototype.config = config;

// 复制
import VueClipBoard from "vue-clipboard2";
Vue.use(VueClipBoard);

import Tinymce from "@/components/Tinymce";
Vue.component("Tinymce", Tinymce);

import "mavon-editor/dist/css/index.css";

import CropperImg from "@/components/CropperImg";
Vue.component("cropper-img", CropperImg);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");

router.beforeEach((to, from, next) => {
  // if (to.meta.title) {
  // }
  next();
});
router.afterEach(() => {
  window.scrollTo({
    top: 0,
    behavior: "instant",
  });
});
