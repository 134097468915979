<template>
  <div class="upload-container">
    <el-button :style="{ background: color, borderColor: color }" icon="el-icon-upload" size="mini" type="primary" @click="dialogVisible = true"> 上传图片 </el-button>
    <el-dialog :visible.sync="dialogVisible" :modal="false" title="上传图片">
      <el-row style="padding-top: 20px">
        <el-col :span="24">
          <!-- :on-success="handleSuccess" -->
          <el-upload :multiple="true" :file-list="fileList" :show-file-list="true" :on-remove="handleRemove" :on-error="handleUploadError" :before-upload="beforeUpload" :http-request="uploadFile" :headers="headers" class="editor-slide-upload" :action="uploadUrl" list-type="picture-card">
            <el-button size="small" type="primary"> 点击上传 </el-button>
          </el-upload>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="text-align: right">
          <el-button @click="dialogVisible = false"> 取消 </el-button>
          <el-button type="primary" @click="handleSubmit"> 确认 </el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
// import { getToken } from 'api/qiniu'
// import { getToken } from "@/utils/auth";
// import { upload } from "@/api/imgUpload";
export default {
  name: "EditorSlideUpload",
  props: {
    color: {
      type: String,
      default: "#1890ff",
    },
  },
  data() {
    return {
      uploadUrl: process.env.VUE_APP_BASE_API + "/file/upload", // 上传的图片服务器地址
      headers: {
        // Authorization: "Bearer " + getToken(),
      },
      dialogVisible: false,
      listObj: {},
      fileList: [],
    };
  },
  computed: {},
  methods: {
    initFromData: function (param) {
      this.files = param.file;
      // FormData 对象
      var formData = new FormData();
      // 文件对象
      formData.append("multipartFile", this.files);
      return formData;
    },
    uploadFile: function (file) {
      let fromData = this.initFromData(file);
      // upload(fromData).then((res) => {
      //   const fileId = file.uid;
      //   const objKeyArr = Object.keys(this.listObj);
      //   let len = objKeyArr.length || 0;
      //   for (let i = 0; i < len; i++) {
      //     if (this.listObj[objKeyArr[i]].fileId === fileId) {
      //       this.listObj[objKeyArr[i]].url = res.data;
      //       this.listObj[objKeyArr[i]].hasSuccess = true;
      //       return;
      //     }
      //   }
      //   this.listObj[fileId].fileId = fileId;
      //   this.listObj[fileId].url = res.data;
      //   this.listObj[fileId].hasSuccess = true;

      //   // this.formHB.img = res.data;
      //   // this.loading.close();
      // });
    },
    checkAllSuccess() {
      return Object.keys(this.listObj).every((item) => this.listObj[item].hasSuccess);
    },
    handleSubmit() {
      const arr = Object.keys(this.listObj).map((v) => this.listObj[v]);
      if (!this.checkAllSuccess()) {
        this.$message("Please wait for all images to be uploaded successfully. If there is a network problem, please refresh the page and upload again!");
        return;
      }
      this.$emit("successCBK", arr);
      this.listObj = {};
      this.fileList = [];
      this.dialogVisible = false;
    },
    handleSuccess(response, file) {
      const fileId = file.fileId;
      const objKeyArr = Object.keys(this.listObj);
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].fileId === fileId) {
          this.listObj[objKeyArr[i]].url = process.env.VUE_APP_BASE_API + response.fileName;
          this.listObj[objKeyArr[i]].hasSuccess = true;
          return;
        }
      }
    },
    handleRemove(file) {
      const fileId = file.fileId;
      const objKeyArr = Object.keys(this.listObj);
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].fileId === fileId) {
          delete this.listObj[objKeyArr[i]];
          return;
        }
      }
    },
    beforeUpload(file) {
      const _self = this;
      const _URL = window.URL || window.webkitURL;
      const fileName = file.fileId;
      this.listObj[fileName] = {};
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = _URL.createObjectURL(file);
        img.onload = function () {
          _self.listObj[fileName] = { hasSuccess: false, fileId: file.fileId, width: this.width, height: this.height };
        };
        resolve(true);
      });
    },
    handleUploadError() {
      this.$message.error("Image insertion failed");
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-slide-upload {
  margin-bottom: 20px;
  ::v-deep .el-upload--picture-card {
    width: 100%;
  }
}
</style>
