<template>
  <div>
    <el-dialog :lock-scroll="false" class="dialog" :visible.sync="dialogFormVisible">
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <section v-if="showSign && pageNum == 1" class="wrap__section">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <!-- Form Login -->
                <div class="card mx-auto" style="max-width: 380px">
                  <div class="card-body">
                    <h4 class="card-title mb-4">Sign in</h4>
                    <form action="#">
                      <a @click="openAuthLogin('oneid')" href="javascript:void(0);" class="btn btn-facebook btn-block mb-2 text-white" style="background-color: #eb5c02; border-color: #eb5c02">
                        <i class="fa">
                          <img src="@/imgs/user/oneid.png" alt="" />
                        </i>
                        &nbsp; Sign in with User-OneID</a
                      >
                      <a @click="openAuthLogin('orcid')" href="javascript:void(0);" class="btn btn-primary btn-block mb-4" style="background-color: #9bcf00; border-color: #9bcf00">
                        <i class="fa">
                          <img src="@/imgs/user/orcid.png" alt="" />
                        </i>
                        &nbsp; Sign in with ORCID</a
                      >
                      <div class="form-group">
                        <input name="account" class="form-control" placeholder="Email Address" v-model="form.email" autocomplete="off" />
                      </div>
                      <div class="form-group">
                        <input id="pwd_1" name="password" type="password" class="form-control" placeholder="Password" v-model="form.password" autocomplete="off" />
                        <img id="eye_1" @click="clickeye(1)" class="eye" src="@/imgs/common/eyeclose.png" alt="" />
                      </div>
                      <div class="form-group">
                        <label class="float-left custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" checked="" />
                          <span class="custom-control-label"> Remember </span>
                        </label>
                        <label @click="pageNum = 3" class="float-right" style="color: #bb490a; cursor: pointer">
                          <span class=""> Forgot Password? </span>
                        </label>
                      </div>
                      <div class="form-group" style="top: 20px">
                        <button @click="login" type="button" class="btn btn-primary btn-block">Login</button>
                      </div>
                    </form>
                  </div>
                </div>

                <p class="text-center mt-4">
                  Don't have account?
                  <a @click="showSignUp" href="javascript:(0)">Sign up</a>
                </p>
              </div>
            </div>
          </div>
        </section>
        <!-- end login -->

        <!-- register -->
        <section v-if="!showSign" class="wrap__section">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <!-- register -->
                <div class="card mx-auto" style="max-width: 520px">
                  <article class="card-body">
                    <header class="mb-4">
                      <h4 class="card-title">Sign up</h4>
                    </header>
                    <form action="#">
                      <div class="form-row">
                        <div class="col form-group">
                          <label>Account</label>
                          <input v-model="form.email" type="email" class="form-control" placeholder="Email Address" autocomplete="off" id="suemail" />
                          <small class="form-text text-muted">We'll never share your email with anyone else.</small>
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Password</label>
                        <div class="form-group">
                          <input id="pwd_2" v-model="form.password" type="password" class="form-control" placeholder="" autocomplete="off" />
                          <img id="eye_2" @click="clickeye(2)" class="eye" src="@/imgs/common/eyeclose.png" alt="" />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <label>Verification code</label>
                          <input v-model="form.captcha" type="text" class="form-control" />
                        </div>
                        <div class="form-group col-md-6">
                          <label>&nbsp;</label>
                          <button @click="getCaptcha()" type="button" :disabled="countdown > 0" class="btn btn-primary btn-block">
                            {{ countdownStr || "Obtain" }}
                          </button>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-12">
                          <label>Nickname</label>
                          <input v-model="form.nickname" class="form-control" type="text" />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <button @click="backLogin" type="button" class="btn btn-light btn-block">Back to Login</button>
                        </div>
                        <div class="form-group col-md-6">
                          <button @click="registere" type="button" class="btn btn-primary btn-block">Register</button>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" checked="" />
                          <span class="custom-control-label"> I am agree with <a href="#">terms and contitions</a> </span>
                        </label>
                      </div>
                    </form>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- forget password -->
        <section v-if="pageNum == 3" class="wrap__section">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <!-- register -->
                <div class="card mx-auto" style="max-width: 520px">
                  <article class="card-body">
                    <header class="mb-4">
                      <h4 class="card-title">Reset Password</h4>
                    </header>
                    <form action="#">
                      <div class="form-group">
                        <label>New Password</label>
                        <div class="form-group">
                          <input id="pwd_3" v-model="form.password" type="password" class="form-control" placeholder="" autocomplete="off" />
                          <img id="eye_3" @click="clickeye(3)" class="eye" src="@/imgs/common/eyeclose.png" alt="" />
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Confirm Password</label>
                        <div class="form-group">
                          <input id="pwd_4" v-model="form.passwordConfirm" type="password" class="form-control" placeholder="" autocomplete="off" />
                          <img id="eye_4" @click="clickeye(4)" class="eye" src="@/imgs/common/eyeclose.png" alt="" />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col form-group">
                          <label>Email Account</label>
                          <input v-model="form.email" type="email" class="form-control" placeholder="Email Address" autocomplete="off" id="suemail" />
                          <!-- <small class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <label>Verification code</label>
                          <input v-model="form.captcha" type="text" class="form-control" />
                        </div>
                        <div class="form-group col-md-6">
                          <label>&nbsp;</label>
                          <button @click="getCaptcha('password')" type="button" :disabled="countdown > 0" class="btn btn-primary btn-block">
                            {{ countdownStr || "Obtain" }}
                          </button>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <button @click="backLogin" type="button" class="btn btn-light btn-block">Back to Login</button>
                        </div>
                        <div class="form-group col-md-6">
                          <button @click="resetPass" type="button" class="btn btn-primary btn-block">Confirm</button>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" checked="" />
                          <span class="custom-control-label"> I am agree with <a href="#">terms and contitions</a> </span>
                        </label>
                      </div>
                    </form>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </el-form>
    </el-dialog>

    <el-dialog :visible.sync="dialogJoinMail" width="30%">
      <!-- {{ dialogVisible }} -->
      <!-- {{ !$store.state.isSetting }} -->
      <p style="word-wrap: break-word; word-break: normal">You have successfully subscribed. Would you like to customize your subscription preferences?</p>
      <div style="margin-top: 28px">
        <button @click="gosetting" type="button" class="btn btn-primary btn-block" style="width: auto; margin: 0 auto">Go to Settings</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { emailCode, emailLogin, emailRegister, getWechatLoginCode, openAuthUrl, updateUserInfo, wxIsLogin, emailReset } from "@/api";
import { setToken, setUrl } from "@/utils/cookieUtil";
import Cookies from "js-cookie";

export default {
  data: function () {
    return {
      pageNum: 1,
      form: {
        email: null,
        password: null,
        nickname: null,
        captcha: null,
        passwordConfirm: null,
      },
      code: null,
      timer: null,
      wechatLoginFlag: false,
      formLabelWidth: "80px",
      wechatLoginCode: null,
      countdown: 0, // 倒计时初始值为 60 秒
      countdownStr: "Obtain", // 倒计时初始值为 60 秒
      rules: {
        email: [{ required: true, message: "email requested", trigger: "blur" }],
        password: [{ required: true, message: "password requested", trigger: "blur" }],
      },
      // dialogVisible: Cookies.get("dialogVisible-setting") == 1 && !this.$store.state.isSetting,
      dialogVisible: Cookies.get("join-1") == 1 && Cookies.get("join-2") == 1,
      // dialogVisible: false,
    };
  },

  computed: {
    dialogJoinMail: {
      set(value) {
        this.$store.state.joinMailFlag = value;
      },
      get() {
        return this.$store.state.joinMailFlag || this.dialogVisible;
      },
    },
    dialogFormVisible: {
      set(value) {
        this.$store.state.loginFlag = value;
      },
      get() {
        return this.$store.state.loginFlag;
      },
    },
    showSign: {
      set(value) {
        this.$store.state.showSign = value;
      },
      get() {
        return this.$store.state.showSign;
      },
    },
  },
  methods: {
    resetPass() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          //发送注册登录
          emailReset(this.form).then((res) => {
            this.$message.success("Password reset successfully, please log in again");
            this.$store.commit("setShowSign", true); // 显示注册
            location.reload();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    clickeye(index) {
      const pwd = document.getElementById("pwd_" + index);
      const eye = document.getElementById("eye_" + index);
      // console.log("==clickeye", index, pwd, eye);
      if (pwd.type == "password") {
        //当眼睛是闭上的时候，点击后：
        pwd.type = "text"; //密码框样式换成文本，即可显示密码
        eye.src = require("@/imgs/common/eyeopen.png"); //图片地址修改为眼睛打开的图片
      } else {
        pwd.type = "password"; //文本框换成密码框
        eye.src = require("@/imgs/common/eyeclose.png"); //图片换成闭眼的
      }
    },
    gosetting() {
      let user = { id: this.$store.state.userInfo.userInfoId, subnews: 1, subevent: 1, subblog: 1, announcements: 1, feedback: 1 };
      updateUserInfo(user);

      Cookies.set("join-1", 0);
      Cookies.set("join-2", 0);
      this.$windowOpen("settings?type=3");
      this.dialogVisible = false;
    },
    backLogin() {
      clearInterval(this.timer);
      this.countdown = 0;
      this.countdownStr = "Obtain";
      this.showSign = true;
      this.pageNum = 1;
    },
    getCaptcha(type) {
      let that = this;
      that.countdown = 120;
      that.timer = setInterval(() => {
        if (that.countdown > 0) {
          that.countdown--;
          that.countdownStr = that.countdown;
        } else {
          that.countdown = 0;
          that.countdownStr = "Refresh";
          // 倒计时结束，处理逻辑
          clearInterval(that.timer);
          that.$message.warning("Captcha not working, please refresh to get");
        }
      }, 1000);
      emailCode(that.form.email, type)
        .then((res) => {
          // this.wechatLoginCode = res.data;
          console.log("===emailCode");
          that.$message.success(res.data || "success");
        })
        .catch((err) => {
          that.countdown = 0;
          that.countdownStr = "Obtain";
          clearInterval(that.timer);
        });
    },
    registere() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          //发送注册登录
          emailRegister(this.form).then((res) => {
            setToken(res.data.token);
            this.$store.commit("setUserInfo", res.data);
            this.close();
            this.$message.success("Login Successful");
            location.reload();
            let join1 = Cookies.get("join-1");
            if (join1) {
              Cookies.set("join-2", 1);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    showSignUp() {
      this.showSign = false;
      this.form = {
        email: null,
        password: null,
        nickname: null,
        captcha: null,
      };
    },
    close() {
      clearInterval(this.timer);
      this.$store.state.loginFlag = false;
      this.wechatLoginFlag = false;
    },
    openWechat() {
      this.getWecahtLoginCode();
      this.wechatLoginFlag = true;
      this.$store.state.loginFlag = false;
    },
    getWecahtLoginCode() {
      getWechatLoginCode().then((res) => {
        this.wechatLoginCode = res.data;
        this.$message.success("Authentication code obtained successfully");
        this.countdown = 60;
        this.timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
            // 轮询判断用户是否已经登录
            wxIsLogin(this.wechatLoginCode).then((res) => {
              setToken(res.data.token);
              this.$store.commit("setUserInfo", res.data);
              this.close();
              if (res.data.email == null) {
                this.$message.warning("Please bind the e-mail address to receive a timely reply");
              } else {
                this.$message.success("Login Successful");
              }
              location.reload();
            });
          } else {
            // 倒计时结束，处理逻辑
            clearInterval(this.timer);
            this.wechatLoginCode = "验证码失效，请刷新获取";
          }
        }, 1000);
      });
    },
    bacKLogin() {
      clearInterval(this.timer);
      this.$store.state.loginFlag = true;
      this.wechatLoginFlag = false;
    },
    isShow(type) {
      return this.$store.state.webSiteInfo.loginTypeList.indexOf(type) != -1;
    },
    //Enter事件
    handkeyEnter(event) {
      if (event.keyCode == 13) {
        this.login();
      }
    },
    login() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          //发送登录请求
          emailLogin(this.form).then((res) => {
            setToken(res.data.token);
            this.$store.commit("setUserInfo", res.data);
            this.close();
            this.$message.success("Login Successful");
            Cookies.set("dialogVisible-setting", 1);
            location.reload();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    qqLogin() {
      //保留当前路径
      this.settingUrl();
      if (navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i)) {
        // eslint-disable-next-line no-undef
        QC.Login.showPopup({
          appId: this.config.QQ_CLIENT_ID,
          redirectURI: this.config.QQ_REDIRECT_URL,
        });
      } else {
        openAuthUrl("qq").then((res) => {
          window.open(res.data);
        });
      }
    },
    openAuthLogin(source) {
      //保留当前路径
      this.settingUrl();
      openAuthUrl(source).then((res) => {
        // window.open(res.data);
        location.href = res.data;
      });
    },
    settingUrl() {
      if (this.$route.path == "/articleInfo") {
        setUrl("articleId=" + this.$route.query.articleId);
      } else {
        setUrl(this.$route.path);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  @media screen and (max-width: 1118px) {
    /deep/ .el-dialog {
      width: 90%;
    }
  }

  @media screen and (min-width: 1119px) {
    /deep/ .el-dialog {
      width: 600px;
      border-radius: 10px;
    }
  }

  .wxImg {
    width: 50%;
    display: block;
    margin: 0 auto;
  }

  .loginBtn {
    margin-top: 30px;
    text-align: center;
    display: block;
    margin: 0 auto;
    width: 50%;
  }

  .social-login-title {
    margin-top: 1.5rem;
    color: #b5b5b5;
    font-size: 0.75rem;
    text-align: center;

    &::before {
      content: "";
      display: inline-block;
      background-color: #d8d8d8;
      width: 60px;
      height: 1px;
      margin: 0 12px;
      vertical-align: middle;
    }

    &::after {
      content: "";
      display: inline-block;
      background-color: #d8d8d8;
      width: 60px;
      height: 1px;
      margin: 0 12px;
      vertical-align: middle;
    }
  }

  .social-login-wrapper {
    margin-top: 1rem;
    font-size: 2rem;
    text-align: center;

    a {
      text-decoration: none;
      margin-left: 20px;
      cursor: pointer;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.form-group {
  position: relative;
  .eye {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 24px;
    right: 16px;
  }
}
</style>
