import { getSkin } from "@/utils/cookieUtil";
export const setSkin = function () {
  setTimeout(() => {
    let skin = getSkin();
    if (skin == null) {
      skin = "shallow";
    }
    let link = document.createElement("link");
    link.type = "text/css";
    link.id = "theme";
    link.rel = "stylesheet";
    link.href = `/assets/${skin}.css`;
    document.getElementsByTagName("head")[0].appendChild(link);
  }, 100);
};
export const uphtmljs = function (that) {
  setTimeout(() => {
    delete require.cache[require.resolve("/public/js/index.bundle.js")];
    if (!that) {
      let nav = document.createElement("script"); //创建script标签
      nav.type = "text/javascript";
      nav.src = require("/public/js/index.bundle"); //静态资源
    } else {
      that.$nextTick(() => {
        let nav = document.createElement("script"); //创建script标签
        nav.type = "text/javascript";
        nav.src = require("/public/js/index.bundle"); //静态资源
      });
    }
  }, 100);
};
