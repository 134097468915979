import { forwarding } from "@/api";

/* 判断各个浏览器版本号 */
export const browserMatch = function matchVesion() {
  let userAgent = navigator.userAgent;
  let rMsie = /(msie\s|trident.*rv:)([\w.]+)/;
  let rEdge = /(edg)\/([\w.]+)/;
  let rFirefox = /(firefox)\/([\w.]+)/;
  let rOpera = /(opera).+version\/([\w.]+)/;
  let rChrome = /(chrome)\/([\w.]+)/;
  let rSafari = /version\/([\w.]+).*(safari)/;
  let ua = userAgent.toLowerCase();
  var match = rMsie.exec(ua);
  if (match !== null) {
    return {
      browser: "IE",
      version: match[2] || "0",
    };
  }
  var rEmatch = rEdge.exec(ua);
  if (rEmatch !== null) {
    return {
      browser: "Edge",
      version: rEmatch[2] || "0",
    };
  }
  var rFmatch = rFirefox.exec(ua);
  if (rFmatch !== null) {
    return {
      browser: rFmatch[1] || "",
      version: rFmatch[2] || "0",
    };
  }
  var rOmatch = rOpera.exec(ua);
  if (rOmatch !== null) {
    return {
      browser: rOmatch[1] || "",
      version: rOmatch[2] || "0",
    };
  }
  var rCmatch = rChrome.exec(ua);
  if (rCmatch !== null) {
    return {
      browser: rCmatch[1] || "",
      version: rCmatch[2] || "0",
    };
  }
  var rSmatch = rSafari.exec(ua);
  if (rSmatch !== null) {
    return {
      browser: rSmatch[2] || "",
      version: rSmatch[1] || "0",
    };
  }
  if (match !== null) {
    return {
      browser: "",
      version: "0",
    };
  }
};

var monthsInEng = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

export function datefomat(time) {
  var date = new Date(time);

  return date.getDate() + " " + monthsInEng[date.getMonth()] + " " + date.getFullYear();
}
export function datefomat1(time) {
  var date = new Date(time);

  return date.getDate();
}
export function datefomat2(time) {
  var date = new Date(time);

  return monthsInEng[date.getMonth()] + " " + date.getFullYear();
}
// 精确到几点几分
export function datefomat3(time) {
  const date = new Date(time);
  let hour = (date.getHours() + "").padStart(2, "0");
  let minute = (date.getMinutes() + "").padStart(2, "0");
  let day = (date.getDate() + "").padStart(2, "0");

  return (
    hour + ":" + minute + "   " + day + " " + monthsInEng[date.getMonth()] + " " + date.getFullYear()

    // +
    // " " +
    // date.getSeconds()
  );
}
export function datefomat4(time) {
  const date = new Date(time);
  let hour = (date.getHours() + "").padStart(2, "0");
  let minute = (date.getMinutes() + "").padStart(2, "0");

  return hour + ":" + minute;
}

const shareUrl = process.env.VUE_APP_URL;

export function clickShare(n, type, id) {
  console.log("share====", n);

  let url = shareUrl + type + "-detail?articleId=" + id;

  if (n == 1) {
    // 分享到推特
    shareTwitter(url);
  } else if (n == 2) {
    // facebook
    shareFacebook(url);
  } else if (n == 3) {
    // Linkedin
    shareLinkedin(url);
  } else if (n == 4) {
    //邮箱
    // location = "mailto:''?subject=Share&body=" + url;
    shareEmail(url);
  } else if (n == 5) {
    // 复制
    this.$copyText(url).then(
      (e) => {
        this.$message({
          showClose: true,
          message: "Copy successfully",
          type: "success",
        });
        // console.log("复制成功：", e);
      },
      (e) => {
        this.$message({
          showClose: true,
          message: "Copy failed",
          type: "error",
        });
        // console.log("复制失败：", e);
      }
    );
  }
  forwarding(id);
}

// 分享到推特
export function shareTwitter(url) {
  return window.open(
    "https://twitter.com/intent/tweet?url=" + encodeURIComponent(url),
    // +
    // "&text=" +
    // encodeURIComponent(title),
    "_blank"
  );
}
// 分享到face
export function shareFacebook(url) {
  return window.open(
    "http://www.facebook.com/sharer.php?u=" + encodeURIComponent(url),
    //  +
    // +"&t=" +
    // encodeURIComponent(title),
    "_blank"
  );
}
// 分享到Linkedin
export function shareLinkedin(url) {
  let _href = "https://www.linkedin.com/shareArticle?mini=true";
  const text = url;
  const link = url; // 分享的页面
  _href += "&title=" + text; //分享标题
  _href += "&url=" + encodeURIComponent(link); //分享链接
  window.open(_href, "_blank");
  // return window.open(
  //   "http://www.linkedin.com/shareArticle?url=" + encodeURIComponent(url),
  //   //  +
  //   // +"&t=" +
  //   // encodeURIComponent(title),
  //   "_blank"
  // );
}
export function shareEmail(url) {
  const subject = url;
  const body = url;
  window.open(`mailto:?subject=${subject}&body=` + encodeURIComponent(body), "_blank");
}

export function windowOpen(url, target) {
  if (url) {
    if (target) {
      window.open(url, target);
    } else {
      location.href = url;
    }
  }
}

export function _isMobile() {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  // localStorage.setItem('isiphone',flag)
  // console.log(flag);
  return flag;
}
