<template>
  <div class="footer_container footerc" style="background: #000; color: #fff">
    <div>
      <el-row class="contantrow" :gutter="20">
        <el-col :span="6" :xs="10">
          <div class="left1">
            <p class="contact">Contact us</p>
            <img src="../../imgs/index/footer-1.png" alt="" />
            <p class="p2">info@paleogeography.com</p>
          </div>
        </el-col>
        <el-col :span="6" :xs="7" class="pr-0">
          <div class="left2">
            <p class="p1">About GPCN</p>

            <p class="p2"><a href="about1">What is GPCN?</a></p>
            <p class="p2"><a href="about2">Membership</a></p>
            <p class="p2"><a href="about3">Opportunities</a></p>
            <p class="p2"><a href="partners">Partners</a></p>
          </div>
        </el-col>
        <el-col :span="6" :xs="7" class="pr-0">
          <div class="left2">
            <p class="p1">Learn More</p>

            <p class="p2"><a href="news">News</a> & <a href="event">Events</a></p>
            <p class="p2"><a href="blog-home">Blogs</a></p>
            <p class="p2">
              <a href="use">Term of Use</a>
            </p>
            <p class="p2">
              <a href="policy">Privacy Policy</a>
            </p>
          </div>
        </el-col>
        <el-col :span="6" :xs="{ span: 24, offset: 0 }" class="pr-0">
          <div class="left3">
            <p class="p1">paleogeography.com</p>

            <p class="p2">A community for International Paleogeography Enthusiasts.</p>
            <div @click="joinMail">
              <p class="p3">Join Mailing List</p>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 20px; border-top: #1f1f1f 1px solid; padding: 20px 0; text-align: center">
        <el-col :offset="6" :span="10" :xs="{ span: 22, offset: 1 }" style="">
          <p style="font-size: 12px" class="ft-10">Copyright 2023, paleogeography | All Rights Reserved</p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import jsCookie from "js-cookie";
export default {
  data() {
    return {
      day: "",
      hours: "",
      minute: "",
      second: "",
      url: "https://nimg.ws.126.net/?url=http%3A%2F%2Fdingyue.ws.126.net%2F2020%2F0601%2Fba14f84ep00qb7neh000xd200jx00bog00gn009q.png&thumbnail=650x2147483647&quality=80&type=jpg",
    };
  },
  created() {
    this.timer = setInterval(this.runTime, 1000);
  },
  methods: {
    joinMail() {
      jsCookie.set("join-1", 1);
      if (!this.$store.state.userInfo) {
        this.$store.commit("setLoginFlag", true);
        return;
      }
      jsCookie.set("join-1", 0);
      this.$store.state.joinMailFlag=true

      // this.$windowOpen("settings?type=3");
    },
    runTime() {
      var timeold = new Date().getTime() - new Date(1627747200000).getTime();
      var msPerDay = 24 * 60 * 60 * 1000;
      var daysold = Math.floor(timeold / msPerDay);
      var day = new Date();
      this.day = daysold;
      this.hours = day.getHours();
      this.minute = day.getMinutes();
      this.second = day.getSeconds();
    },
  },
};
</script>

<style lang="scss" scoped>
.footerc {
  padding: 90px 0 20px;
  padding-left: 10px;
  padding-right: 10px;

  .contantrow {
    width: 1250px;
  }
}

a {
  color: #fff;
}
</style>
<style lang="scss" scoped>
@keyframes bgSize {
  0% {
    background-size: 300% 100%;
  }

  100% {
    background-size: 100% 100%;
  }
}

@keyframes yb {
  10% {
    transform: rotate(15deg);
  }

  20% {
    transform: rotate(-10deg);
  }

  30% {
    transform: rotate(5deg);
  }

  40% {
    transform: rotate(-5deg);
  }

  50%,
  100% {
    transform: rotate(0deg);
  }
}

.footer_container {
  //   padding: 15px 20px;
  position: relative;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--footer-back-color);
  background-position: 100% 0;
  animation: bgSize 5s infinite ease-in-out alternate;
  margin-top: 50px;
  .content {
    margin: 0 auto;
    position: relative;
    text-align: center;

    .site {
      font-size: 14px;
      color: var(--text-color);
      margin-left: 10px;

      p {
        margin: 10px 0;
      }

      a {
        text-decoration: none;
        color: var(--text-color);

        &:hover {
          color: var(--theme-color);
        }
      }

      .run_time {
        height: 20px;
        align-items: center;

        svg {
          width: 19px;
          height: 19px;
          vertical-align: -4px;
          animation: yb 1s linear infinite;
        }

        span {
          line-height: 20px;
          font-size: 14px;
          padding-left: 5px;

          strong {
            color: var(--theme-color);
          }
        }
      }
    }

    .site-number {
      i {
        display: inline-block;
        font-size: 12px;
      }

      .site_pv,
      .site_uv,
      .online_user {
        position: relative;
        display: inline-block;
        margin-right: 4px;
        height: 24px;
        padding-left: 10px;
        line-height: 24px;
        overflow: hidden;
        font-size: 12px;
        font-weight: 700;
        color: var(--site-text-color);
        background: var(--site-color);
        border-radius: 5px;
      }

      .pv_num,
      .uv_num,
      .online_num {
        display: inline-block;
        font-size: 12px;
        padding: 0 15px;
      }

      .pv_num {
        background: linear-gradient(0deg, #d0339b, #d03336);
      }

      .uv_num {
        background: linear-gradient(0deg, #e2c63a, #f58044);
      }

      .online_num {
        background: linear-gradient(0deg, #367eda, #964fdb);
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.left1 {
  .contact {
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    line-height: 24px;

    margin-bottom: 24px;
  }
  img {
    width: 102px;
    height: 102px;
    margin-bottom: 12px;
  }
  .p2 {
    font-size: 16px;
    color: #ffffff;
    line-height: 22px;
  }
}
.left2 {
  .p1,
  .p2 {
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    line-height: 24px;
    cursor: pointer;
    margin-bottom: 14px;
  }
  .p2 {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
  }
}
.left3 {
  .p1 {
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    line-height: 24px;
    margin-bottom: 30px;
  }
  .p2 {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 25px;
    margin-bottom: 14px;
  }
  .p3 {
    width: 199px;
    height: 44px;
    background: #bb490a;

    font-size: 18px;
    color: #ffffff;
    line-height: 44px;
    font-weight: 300;
    text-align: center;

    cursor: pointer;
  }
}
</style>

<!-- 适配 -->
<style lang="scss" scoped>
@media screen and (max-width: 600px) {
  .contantrow {
    width: 100% !important;
  }
  .footerc {
    padding-top: 40px;
  }

  .left1 {
    img {
      width: 50px;
      height: 50px;
    }
  }
  .p1,
  .contact,
  .p3 {
    font-size: 12px !important;
    margin-bottom: 6px !important;
  }
  .p2 {
    font-size: 10px !important;
    margin-bottom: 6px !important;
  }
  .left3 {
    margin-top: 20px;
    border-top: #1f1f1f 1px solid;
    padding: 20px 0;
    .p1 {
      margin-bottom: 10px;
    }
    .pr-0 {
      padding-right: 0 !important;
    }
    .p3 {
      width: 150px !important;
      height: 26px;
      line-height: 26px;
    }
  }

  .ft-10 {
    font-size: 10px !important;
  }
}
</style>
