import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=9d32744c&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&id=9d32744c&prod&lang=scss&scoped=true&"
import style1 from "./Footer.vue?vue&type=style&index=1&id=9d32744c&prod&lang=scss&scoped=true&"
import style2 from "./Footer.vue?vue&type=style&index=2&id=9d32744c&prod&lang=scss&scoped=true&"
import style3 from "./Footer.vue?vue&type=style&index=3&id=9d32744c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.10.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d32744c",
  null
  
)

export default component.exports