<template>
  <div>
    <div @click="showPop" style="height: inherit">
      <img v-if="value" :src="value" alt="" style="width: 100%; height: inherit; object-fit: cover" />
      <i v-else class="el-icon-plus avatar-img-icon avatar-uploader" style="width: 100%; height: inherit"></i>
    </div>

    <el-dialog title="CropperImage" :visible.sync="isShowImgPop" :width="cwidth + 'px'" center append-to-body>
      <div class="cropper-content">
        <div class="cropper-box">
          <div class="cropper" :style="{ height: height + 'px' }">
            <vue-cropper ref="cropper" :img="option.img" :outputSize="option.outputSize" :outputType="option.outputType" :info="option.info" :canScale="option.canScale" :autoCrop="option.autoCrop" :autoCropWidth="option.autoCropWidth" :autoCropHeight="option.autoCropHeight" :fixed="option.fixed" :fixedNumber="option.fixedNumber" :full="option.full" :fixedBox="option.fixedBox" :canMove="option.canMove" :canMoveBox="option.canMoveBox" :original="option.original" :centerBox="option.centerBox" :height="option.height" :infoTrue="option.infoTrue" :maxImgSize="option.maxImgSize" :enlarge="option.enlarge" :mode="option.mode" @realTime="realTime" @imgLoad="imgLoad"> </vue-cropper>
          </div>
          <!-- <div class="show-preview">
            <div :style="previews.div" class="preview">
              <img :src="previews.url" :style="previews.img" />
            </div>
          </div> -->
        </div>
        <div class="handle-cropper">
          <div class="scope-btn">
            <label class="btn el-button" for="uploads">Select image</label>
            <input type="file" id="uploads" style="position: absolute; clip: rect(0 0 0 0)" accept="image/png, image/jpeg, image/gif, image/jpg" @change="selectImg($event)" />
          </div>
          <div class="scope-btn2">
            <el-button size="mini" type="danger" plain icon="el-icon-zoom-in" @click="changeScale(1)"></el-button>
            <el-button size="mini" type="danger" plain icon="el-icon-zoom-out" @click="changeScale(-1)"></el-button>
            <el-button size="mini" type="danger" plain @click="rotateLeft">↺ </el-button>
            <el-button size="mini" type="danger" plain @click="rotateRight">↻ </el-button>
          </div>
          <div class="upload-btn">
            <el-button size="mini" type="primary" @click="onConfirm('blob')">Confirm <i class="el-icon-upload"></i></el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";
import { upload } from "@/api";
// import { uploadImg } from "@/api/img";
// import { updateUser } from "@/api/user";
export default {
  name: "CropperImage",
  components: {
    VueCropper,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    height: {
      type: Number,
      default: 200,
    },
    width: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      isShowImgPop: false,
      previews: {},
      option: {
        img: this.value, // 裁剪图片的地址
        outputSize: 1, // 裁剪生成图片的质量(可选0.1 - 1)
        outputType: "png", // 裁剪生成图片的格式（jpeg || png || webp）
        info: true, // 图片大小信息
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixed: false, // 是否开启截图框宽高固定比例
        // fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // false按原比例裁切图片，不失真
        fixedBox: true, // 固定截图框大小，不允许改变
        canMove: false, // 上传图片是否可以移动
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        height: false, // 是否按照设备的dpr 输出等比例图片
        infoTrue: false, // true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000, // 限制图片最大宽度和高度
        enlarge: 2, // 图片根据截图框输出比例倍数
      },
      cwidth: this._isMobile() ? 300 : 800,
    };
  },
  methods: {
    showPop() {
      this.option.img = this.value;
      // 同比缩放 宽度800
      let metchH = this.height;
      let w = this.cwidth - 100;
      if (this.width > w) {
        let r = this.width / w;
        metchH = metchH / r;
      } else {
        w = this.width;
      }
      this.option.autoCropHeight = metchH;
      this.option.autoCropWidth = w;

      // this.option.autoCropHeight = this.height/2;
      // this.option.autoCropWidth = this.width/2;
      // console.log(this.$refs.cropper);
      // this.$refs.cropper.cropW = this.width / 2;
      // this.$refs.cropper.cropH = this.height / 2;
      this.isShowImgPop = true;
    },
    // 初始化函数
    imgLoad(msg) {
      console.log("工具初始化函数=====" + msg);
      console.log(this.$refs.cropper);
      // this.$refs.cropper.cropW = 300;
      // this.$refs.cropper.cropH = 100;
    },
    // 图片缩放
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    // 向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    // 实时预览函数
    realTime(data) {
      this.previews = data;
    },
    // 选择图片
    selectImg(e) {
      const file = e.target.files[0];
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        this.$message({
          message: "Image Type Requirements：jpeg、jpg、png",
          type: "error",
        });
        return false;
      }
      // 转化为blob
      const reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        this.option.img = data;
      };
      // 转化为base64
      reader.readAsDataURL(file);
    },
    // 上传图片
    onConfirm(type) {
      if (type === "blob") {
        // 获取截图的blob数据
        this.$refs.cropper.getCropBlob((blob) => {
          //   this.updatePhoto(blob);
          this.uploadSectionFile(blob);
        });
      }
    },
    uploadSectionFile: function (blob) {
      // FormData 对象
      var formData = new FormData();
      // 文件对象
      formData.append("multipartFile", blob, ".png");
      upload(formData).then((res) => {
        // this.article.avatar = res.data;
        this.$emit("input", res.data);
        this.isShowImgPop = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.cropper-content {
  //   display: flex;
  flex-direction: column;
  .cropper-box {
    // display: flex;
    // justify-content: space-between;
    width: 100%;
    .cropper {
      width: 100%;
      height: 300px;
    }
    .show-preview {
      width: 250px;
      display: flex;
      justify-content: center;
      .preview {
        overflow: hidden;
        border: 1px solid #ccc;
        border-radius: 50%;
        background: #fff;
        box-sizing: border-box;
      }
    }
  }
  .handle-cropper {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .scope-btn {
      display: flex;
      display: -webkit-flex;
      //   justify-content: space-between;
      padding-right: 10px;
      margin: 0 auto;
      margin-bottom: 10px;
    }
    .scope-btn2 {
      flex: 1;
      -webkit-flex: 1;
      display: flex;
      display: -webkit-flex;
      justify-content: center;
      padding-right: 10px;
      margin-bottom: 10px;
    }
    .upload-btn {
      // flex: 1;
      // -webkit-flex: 1;
      display: flex;
      display: -webkit-flex;
      //   justify-content: end;
      margin: 0 auto;
      margin-bottom: 10px;
    }
    .btn {
      outline: none;
      display: inline-block;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
      -webkit-appearance: none;
      text-align: center;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      outline: 0;
      -webkit-transition: 0.1s;
      transition: 0.1s;
      font-weight: 500;
      padding: 8px 15px;
      font-size: 12px;
      border-radius: 3px;
      color: #fff;
      background-color: #bb490a;
      border-color: #bb490a;
      margin-right: 10px;
    }
  }
}
</style>

<style lang="scss" scoped>
.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 100px;
  text-align: center;
  vertical-align: middle;
}
::v-deep .el-icon-plus:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
::v-deep .crop-info {
  display: none;
}
::v-deep.el-button--primary {
  border-color: #bb490a;
  background: #bb490a;
}
::v-deep.el-button--primary.is-plain {
  color: #fff;
}
::v-deep.el-button--primary.is-plain:hover {
  color: #bb490a;
}
</style>
